export default ({ data, type }) => {
    return {
        title: '基础',
        form: [
            {
                type: 'textGroup',
                labelCol: { span: 3 },
                wrapperCol: { span: 24 },
                value: [
                    {
                        name: '用户姓名',
                        key: 'buyName',
                        display: true
                    },
                    {
                        name: '订单状态',
                        value:
                            {
                                0: '待付款',
                                1: '待使用',
                                2: '待发货',
                                3: '待签收',
                                4: '待评价',
                                5: '已完成',
                                6: '已关闭',
                            }[data.status] || '',
                        display: true
                    },
                    {
                        name: '登陆手机',
                        key: 'buyPhone',
                        display: true
                    },
                    {
                        name: '订单编号',
                        key: 'orderSn',
                        display: true
                    },
                    {
                        name: '联系电话',
                        key: 'buyPhone',
                        display: true
                    },

                    {
                        name: '支付方式',
                        value: '微信',
                        display: true
                    },
                    {
                        name: '出发时间',
                        key: 'busTime',
                        display: true
                    },
                ].filter((e) => e.display),
            },
        ],
    }
}
