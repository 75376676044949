export default ({ data }) => {
  if (data.refundList && data.refundList.length > 0) {
    let dataCopy = data.refundList[0]
    return {
      title: '退款信息',
      form: [
        {
          type: 'textGroup',
          value: [
            {
              name: '申请时间',
              value: dataCopy.createTime,
              display: dataCopy.createTime,
              width: '50%'
            },
            {
              name: '退款金额',
              value: dataCopy.returnAmount,
              display: dataCopy.returnAmount,
              width: '50%'
            },
            {
              name: '退款原因',
              value: dataCopy.reason,
              display: dataCopy.reason,
              width: '100%'
            },
            {
              name: '退款人',
              value: dataCopy.handleMan,
              display: dataCopy.handleMan,
              width: '100%'
            },
            {
              name: '更多原因',
              value: dataCopy.handleNote,
              display: dataCopy.handleNote,
              width: '100%'
            }
          ].filter(e => e.display)
        }
      ]
    }
  } else {
    return []
  }
}
