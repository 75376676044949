export default function({ form, data }) {
  let { typeText } = data
  return [...new Array(form.zNum)].map((_, i) => {
    if (i === 0) {
      return {
        title: '房间' + typeText,
        styles: {
          borderBottom: '0',
          marginBottom: '-5px'
        },
        form: [
          {
            name: typeText + '日期',
            type: 'checkBoxButton',
            key: 'offDate' + i,
            cols: 12,
            labelAlign: 'left',
            labelCol: { span: 5 },
            wrapperCol: { span: 19 },
            typeData: [
              {
                name: `${form['date' + i]}（${form['numRoom' + i]}间）`,
                value: form['id' + i]
              }
            ],
            onChange: val => {
              form['offNum' + i] = val.length ? 1 : 0
            }
          },
          {
            name: typeText + '间数',
            type: 'stepper',
            key: 'offNum' + i,
            cols: 12,
            labelAlign: 'left',
            labelCol: { span: 5 },
            wrapperCol: { span: 19 },
            styles: {
              marginTop: '5px'
            },
            maxValue: `${form['numRoom' + i]}`,
            onChange: val => {
              if (val === 0) {
                form['offDate' + i].length = 0
              } else if (val === 1) {
                form['offDate' + i] = [form['id' + i]]
              }
            }
          }
        ]
      }
    } else {
      return {
        styles: {
          borderBottom: '0',
          marginBottom: '-5px'
        },
        form: [
          {
            type: 'checkBoxButton',
            key: 'offDate' + i,
            cols: 12,
            labelAlign: 'left',
            labelCol: { span: 5 },
            wrapperCol: { span: 19 },
            styles: {
              marginLeft: '26%'
            },
            typeData: [
              {
                name: `${form['date' + i]}（${form['numRoom' + i]}间）`,
                value: form['id' + i]
              }
            ],
            onChange: val => {
              form['offNum' + i] = val.length ? 1 : 0
            }
          },
          {
            type: 'stepper',
            key: 'offNum' + i,
            cols: 12,
            labelAlign: 'left',
            labelCol: { span: 5 },
            wrapperCol: { span: 19 },
            styles: {
              marginTop: '5px',
              marginLeft: '26%'
            },
            maxValue: `${form['numRoom' + i]}`,
            onChange: val => {
              if (val === 0) {
                form['offDate' + i].length = 0
              } else if (val === 1) {
                form['offDate' + i] = [form['id' + i]]
              }
            }
          }
        ]
      }
    }
  })
}
