function getTableColumns(data) {
  const columns = [].concat(data.orderItemList)
  const arr = [
    {
      name: `V${data.memberLevel}优惠`,
      value: '-￥' + data.memberDiscountAmount,
      display: data.memberDiscountAmount,
    },
    {
      name: '运费',
      value: '￥' + data.freightAmount,
      display: data.freightAmount,
    },
    {
      name: '运费',
      value: '包邮',
      display: data.fromType == '1' && !data.freightAmount,
    },
    {
      name: '优惠券',
      //   name: `优惠券${data.couponName}`,
      value: '-￥' + data.couponAmount,
      display: data.couponAmount,
    },
    {
      name: '余额券',
      value: '-￥' + data.balanceAmount,
      display: data.balanceAmount,
    },
    {
      name: '实付金额',
      value: '￥' + data.payAmount,
      display: data.payAmount != undefined,
    },
  ].filter((e) => e.display)
  arr.forEach((e) => {
    columns.push({
      productQuantity: e.name,
      totalPrice: e.value,
    })
  })
  return columns
}
export default function () {
  const { data, type } = arguments[0]
  return {
    class: 'order-table',
    form: [
      {
        type: 'table',
        wrapperCol: 24,
        dataSource: getTableColumns(data),
        showPagination: false,
        columns: [
          {
            title: '商品',
            align: 'left',
            display: true,
            customRender: (text, records, index, h) => {
              if (!records.productPic || !records.productName) return null
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  {records.productPic.trim() && (
                    <img
                      src={records.productPic}
                      style={{
                        width: '30px',
                        height: '30px',
                        marginRight: '10px',
                      }}
                    />
                  )}
                  <div
                    style={{
                      maxWidth: '260px',
                      // overflow: 'hidden',
                      // textOverflow: 'ellipsis',
                      // whiteSpace: 'nowrap',
                    }}
                  >
                    {/* {records.productName.length > 10
                      ? records.productName.substring(0, 10) + '……'
                      : records.productName} */}
                    {records.productName}
                    <br />
                    {type == 'specialty' ? records.productAttr : ''}
                  </div>
                </div>
              )
            },
          },
          {
            title: '床类型',
            dataIndex: 'productAttr',
            type: 'ct-100',
            display: type == 'hotel',
          },
          {
            title: '数量',
            dataIndex: type == 'hotel' ? 'productQuantityTotal' : 'productQuantity',
            type: 'ct-100',
            display: true,
          },
          {
            title: '总价',
            display: true,
            customRender: (text, records, index, h) => {
              if (records.totalPrice) return records.totalPrice
              return (
                <div>
                  ￥
                  {(type == 'hotel' ? records['productQuantityTotal'] : records['productQuantity']) *
                    records.productPrice}
                </div>
              )
            },
          },
          {
            title: '已退款',
            dataIndex: 'refundAmount',
            display: data.fromType != '14',
            customRender: (text, records, index, h) => {
              return <div> {records.refundAmount != undefined ? '￥' + (records.refundAmount ?? 0) : ''}</div>
            },
          },
        ].filter((e) => e.display),
      },
    ],
  }
}
