import apiTool from '@/command/apiTool'
import { getAction, putAction } from '@/command/netTool'
import { Message, Modal } from 'ant-design-vue'

let [selectedRowKeysArr, customOrderIds, customOrderItemIds, fromType] = [[], '', '', '']

function getTableColumns(data) {
  const columns = [].concat(data.userCodeVOList)
  return columns
}

const resetting = () => {
  selectedRowKeysArr = []
  customOrderIds = ''
  customOrderItemIds = ''
  fromType = ''
}

/* 同步订单 */
function syncOrder(setHidden, success) {
  getAction(`/ticketOrder/orderSync?orderId=${customOrderIds}`).then((res) => {
    if (res.code == 200) {
      Message.success('操作成功')
      success()
      setHidden()
      selectedRowKeysArr = []
    } else {
      Message.error(res.msg)
    }
  })
}

/* 核销 */
function verification(setHidden, success, data) {
  if (typeof handleData(data) != 'boolean') {
    const url = {
      2: '/agr/order/hx',
      4: '/farming/order/hx',
      12: '/ticketOrder/hx',
      13: '/room/order/hxCode',
      14: '/combo/order/hx',
    }[fromType]
    putAction(url, handleData(data)).then((res) => {
      if (res.code == 200) {
        Message.success('操作成功')
        success()
        setHidden()
        selectedRowKeysArr = []
      } else {
        Message.error(res.msg)
      }
    })
  }
}

/* 取消核销 */
function unVerification(setHidden, success) {
  if (typeof handleData(data) != 'boolean') {
    putAction('/ticketOrder/cancelHx', handleData()).then((res) => {
      if (res.code == 200) {
        Message.success('操作成功')
        success()
        setHidden()
        selectedRowKeysArr = []
      } else {
        Message.error(res.msg)
      }
    })
  }
}

/* 退款 */
function refund(setHidden, success, data) {
  if (typeof handleData(data) != 'boolean') {
    const url = {
      2: '/agr/order/refund',
      4: '/farming/order/refund',
      12: '/ticketOrder/refund',
      13: '/room/order/refund',
      14: '/combo/order/refund',
    }[fromType]
    const callBack = (res) => {
      if (res.code == 200) {
        if (res.data == '5') {
          Message.error('退款失败')
        } else {
          Message.success('操作成功')
          success()
          setHidden()
          selectedRowKeysArr = []
        }
      } else {
        Message.error(res.msg)
      }
    }
    if (fromType == 14 || fromType == 13) {
      getAction(url, handleData(data)).then(callBack)
    } else {
      putAction(url, handleData(data)).then(callBack)
    }
  }
}

/* 取消第三方票务关联 */
function cancelAssociated(setHidden, success) {
  getAction('/ticketOrder/disassociate?orderId=' + customOrderIds).then((res) => {
    if (res.code == 200) {
      Message.success('操作成功')
      success()
      setHidden()
      selectedRowKeysArr = []
    } else {
      Message.error(res.msg)
    }
  })
}

function handleData(data) {
  if (!selectedRowKeysArr.length) {
    Message.warning('请选择需要操作的核销码')
    return false
  } else {
    return {
      orderId: data.orderItemList[0].orderId,
      orderItemId: data.orderItemList[0].id,
      userCodeList: selectedRowKeysArr,
    }
  }
}

function handleButton(str) {
  let buttonArr = []
  // if (process.env.NODE_ENV === 'development') {
    buttonArr = ['同步订单', '核销', '取消核销', '退款', '取消第三方票务关联']
  // } else {
  //   buttonArr = apiTool.getButtonName()
  // }
  return buttonArr.includes(str)
}

export default function () {
  const { data, customOrderId, customOrderItemId, setHidden, success } = arguments[0]
  const { showButton = true } = data
  customOrderIds = customOrderId
  customOrderItemIds = customOrderItemId
  fromType = data.fromType
  selectedRowKeysArr = []
  return {
    class: 'order-table',
    form: [
      {
        display: true,
        type: 'table',
        wrapperCol: 24,
        dataSource: getTableColumns(data),
        showRowSelect: true,
        showPagination: false,
        selectedRowKeys: [],
        rowKey: 'code',
        onAddCustom: (e) => {
          selectedRowKeysArr = e
        },
        rowSelection: {
          getCheckboxProps: (record) => ({
            props: {
              disabled: record.status !== '0',
            },
          }),
        },
        columns: [
          {
            title: '核销码',
            type: 'lt-200',
            display: true,
            customRender: (text, records, index, h) => {
              return <div> {records.code}</div>
            },
          },
          {
            title: '商品',
            dataIndex: 'productName',
            type: 'ct-100',
            display: true,
            // display: data.fromType !== '14',
            // customRender: (text, records, index, h) => {
            //   ret
            // },
          },
          {
            title: '分销商',
            dataIndex: 'thirdPlatform',
            type: 'ct-100',
            display: true,
            // display: data.fromType !== '14',
            customRender: (text, records, index, h) => {
              return { 0: '自我游', 1: '云上游' }[records.thirdPlatform] || ''
            },
          },
          {
            title: '状态',
            dataIndex: 'status',
            type: 'ct-100',
            display: true,
            customRender: (text, records, index, h) => {
              return { 0: '未使用', 1: '已使用', 2: '已退款', 3: '退款中', 4: '已过期' }[records.status] || ''
            },
          },
          {
            title: '时间',
            display: true,
            dataIndex: 'updateTime',
          },
        ].filter((e) => e.display),
      },
      // {
      //   display: data.thirdLinkStatus == '0' && handleButton('同步订单'),
      //   type: 'button',
      //   cols: 4,
      //   label: '同步订单',
      //   props: {
      //     type: 'primary',
      //     on: {
      //       click: () => {
      //         syncOrder(setHidden, success)
      //       },
      //     },
      //   },
      // },
      {
        display: data.status == '1' && handleButton('核销') && data.thirdTicket !== '1' && showButton,
        type: 'button',
        cols: 4,
        label: '核销',
        props: {
          type: 'primary',
          on: {
            click: () => {
              verification(setHidden, success, data)
            },
          },
        },
      },
      // {
      //   display: data.status == '1' && handleButton('取消核销') && data.thirdTicket !== '1',
      //   type: 'button',
      //   cols: 4,
      //   label: '取消核销',
      //   props: {
      //     style: {
      //       background: '#f56c6c',
      //       color: '#ffffff',
      //     },
      //     on: {
      //       click: () => {
      //         unVerification(setHidden, success)
      //       },
      //     },
      //   },
      // },
      {
        display: data.status == '1' && handleButton('退款') && data.thirdTicket !== '1' && showButton,
        type: 'button',
        cols: 4,
        label: '退款',
        props: {
          style: {
            background: '#f56c6c',
            color: '#ffffff',
          },
          on: {
            click: () => {
              refund(setHidden, success, data)
            },
          },
        },
      },
      // {
      //   display: data.thirdLinkStatus == '0' && handleButton('取消第三方票务关联'),
      //   type: 'button',
      //   cols: 6,
      //   label: '取消第三方票务关联',
      //   props: {
      //     style: {
      //       background: '#f56c6c',
      //       color: '#ffffff',
      //     },
      //     on: {
      //       click: () => {
      //         Modal.confirm({
      //           title: '提示',
      //           content: '取消后将无法获取三方的核销及退款状态',
      //           okText: '确认',
      //           cancelText: '取消',
      //           onOk: () => {
      //             cancelAssociated(setHidden, success)
      //           },
      //         })
      //       },
      //     },
      //   },
      // },
      // {
      //   display: data.thirdLinkStatus == '0' && handleButton('取消第三方票务关联'),
      //   type: 'text',
      //   cols: 24,
      //   styles: {
      //     fontSize: '10px',
      //     color: '#f56c6c',
      //     width: '110%',
      //   },
      //   value:
      //     '温馨提示：该订单为三方票务订单，退款后请到对应票务平台进行同步退款审核，否则造成的损失，由商家自己承担。',
      // },
    ].filter((e) => e.display),
  }
}
