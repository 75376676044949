export default ({ data, type }) => {
    return {
        title: '车辆/驾驶员信息',
        form: [
            {
                type: 'textGroup',
                labelCol: { span: 3 },
                wrapperCol: { span: 24 },
                value: [
                    {
                        name: '驾驶员姓名',
                        key: 'driverName',
                        display: true
                    },
                    {
                        name: '证件类型',
                        value:
                            {
                                10: '居民身份证',
                                20: '其他',
                            }[data.driverCertType] || '',
                        display: true
                    },
                    {
                        name: '车辆类型',
                        key: 'ticketTypeName',
                        display: true
                    },
                    {
                        name: '证件号码',
                        key: 'driverCertNo',
                        display: true
                    },
                    {
                        name: '车牌',
                        key: 'plateNo',
                        display: true
                    },
                ].filter((e) => e.display),
            },
        ],
    }
}
