export default ({ typeData, form }) => {
  return {
    display: 1,
    form: [
      {
        name: '发货方式',
        type: 'select',
        key: 'deliveryType',
        display: true,
        labelAlign: 'left',
        typeData: [
          {
            name: '快递配送',
            value: '0'
          },
          {
            name: '自行配送',
            value: '1'
          }
        ]
      },
      {
        name: '发货方式',
        type: 'select',
        key: 'deliveryCode',
        labelAlign: 'left',
        typeData: typeData.courierData || [],
        display: form.deliveryType == '0'
      },
      {
        name: '快递单号',
        type: 'input',
        key: 'deliverySn',
        labelAlign: 'left',
        display: form.deliveryType == '0'
      }
    ].filter(e => e.display)
  }
}
