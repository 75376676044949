function getTableColumns(data){
    return [
         data
    ]
}


export default function () {
    const { data, type } = arguments[0]
    return {
        class: 'order-table',
        title:"资金信息",
        form: [
            {
                type: 'table',
                wrapperCol: 24,
                dataSource: getTableColumns(data),
                showPagination: false,
                columns: [
                    {
                        title: '订单金额',
                        dataIndex: 'totalAmount',
                        display: true,
                    },
                    {
                        title: '优惠金额',
                        dataIndex: 'productAttr',
                        display: true,
                        customRender: (text, records, index, h) => {
                            return records.couponAmount && `${records.couponAmount + records.balanceAmount}`
                        },
                    },
                    {
                        title: '实付金额',
                        display: true,
                        dataIndex: 'payAmount',
                        
                    },

                ].filter((e) => e.display),
            },
        ],
    }
}
