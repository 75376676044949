import FormStepper from '../components/FormView/FormStepper'
function getTableColumns(data) {
  const columns = [].concat(data.orderItemList)
  let arr = columns.map(e => {
    return {
      ...e,
      returnNum: e.returnNum ?? 0
    }
  })
  return arr
}

export default function() {
  const { data, type } = arguments[0]
  let cup1 = data.userCodeVOList ? data.userCodeVOList.filter(e => e.status == 0).length : 0
  let cup2 = data.userCodeVOList ? data.userCodeVOList.length : 0
  return {
    class: 'order-table',
    form: [
      {
        type: 'table',
        dataSource: getTableColumns(data),
        showPagination: false,
        // showHeader: false,
        wrapperCol: 24,
        columns: [
          {
            title: '商品',
            type: 'lt-200',
            customRender: (text, records, index, h) => {
              if (!records.productPic || !records.productName) return null
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                  }}
                >
                  <img
                    src={records.productPic}
                    style={{
                      width: '30px',
                      height: '30px',
                      marginRight: '10px'
                    }}
                  />
                  <div style="width: 60%">
                    <div>
                      {records.productName.length > 10
                        ? records.productName.substring(0, 10) + '……'
                        : records.productName}
                    </div>
                    <div>
                      待使用：{cup1}/{cup2}
                    </div>
                  </div>
                </div>
              )
            }
          },
          {
            title: '实付单价',
            dataIndex: 'productRealPrice',
            type: 'ct-100'
          },
          {
            title: '退款数量',
            type: 'lt-100',
            dataIndex: 'returnNum',
            customRender: (text, records, index, h) => {
              return (
                <FormStepper
                  value={text}
                  onChange={data => {
                    console.log(data)
                    records.returnNum = data
                  }}
                />
              )
            }
          }
        ]
      }
    ]
  }
}
