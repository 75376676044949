export default ({ data }) => {
  return {
    title: '',
    form: [
      {
        type: 'text',
        value: `<div>
        <img style="width: 60px;height: 60px;border-radius: 6px;margin-right:8px" src="${
          data?.orderItemList[0]?.productPic
        }"></img>
          ${data?.orderItemList[0]?.productName || ''}
        </div>`,
      },
    ],
  }
}