export default ({ data, type }) => {
  return {
    title: '基本信息',
    form: [
      {
        type: 'textGroup',
        labelCol: { span: 3 },
        wrapperCol: { span: 24 },
        value: [
          {
            name: '用户姓名',
            key: 'nickname',
            display: data.nickname,
          },
          {
            name: '订单状态',
            value:
              data.refundStatus === '1'
                ? '待退款'
                : {
                    0: '待付款',
                    1: '已报名',
                    2: '已报名',
                    3: '已报名',
                    4: '待评价',
                    5: '已完成',
                    6: '已关闭',
                    102: '待确认',
                    101: '出票中',
                  }[data.orderStatus] || '',
            display: data.orderStatus,
          },
          {
            name: '登录手机',
            key: 'phone',
            display: data.phone,
          },
          {
            name: '订单编号',
            key: 'orderSn',
            display: data.orderSn,
          },
        ].filter((e) => e.display),
      },
    ],
  }
}
