export default ({ data }) => {
  return {
    title: '购买方信息',
    form: [
      {
        type: 'textGroup',
        value: [
          {
            name: '收货人',
            key: 'buyName',
            display: data.buyName
          },
          {
            name: '收货人电话',
            key: 'buyPhone',
            display: data.buyPhone
          },
          {
            name: '实付金额',
            key: 'payAmount',
            display: data.payAmount
          }
        ].filter(e => e.display)
      }
    ]
  }
}
