import { Button, Input } from 'ant-design-vue'

function getTableColumns(data) {
  const columns = [].concat(data.orderItemList)
  //   const arr = [
  //     {
  //       name: `V${data.memberLevel}优惠`,
  //       value: '-￥' + data.memberDiscountAmount,
  //       display: data.memberDiscountAmount
  //     },
  //     {
  //       name: `优惠券${data.couponName}`,
  //       value: '-￥' + data.couponAmount,
  //       display: data.couponAmount
  //     },
  //     {
  //       name: '运费',
  //       value: '-￥' + data.freightAmount,
  //       display: data.freightAmount
  //     },
  //     {
  //       name: '实付金额',
  //       value: '￥' + data.payAmount,
  //       display: data.payAmount
  //     }
  //   ].filter(e => e.display)
  //   arr.forEach(e => {
  //     columns.push({
  //       productQuantity: e.name,
  //       totalPrice: e.value
  //     })
  //   })
  return columns
}

export default function() {
  const { data, type } = arguments[0]
  let cup1 = data.userCodeVOList ? data.userCodeVOList.filter(e => e.status == 0).length : 0
  let cup2 = data.userCodeVOList ? data.userCodeVOList.length : 0
  return {
    class: 'order-table1',
    form: [
      {
        type: 'table',
        wrapperCol: 24,
        dataSource: getTableColumns(data),
        showPagination: false,
        showHeader: false,
        cols: 19,
        columns: [
          {
            title: '商品',
            type: 'lt-200',
            customRender: (text, records, index, h) => {
              if (!records.productPic || !records.productName) return null
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                  }}
                >
                  <img
                    src={records.productPic}
                    style={{
                      width: '30px',
                      height: '30px',
                      marginRight: '10px'
                    }}
                  />
                  <div style="width: 60%">
                    <div>
                      {records.productName.length > 10
                        ? records.productName.substring(0, 10) + '……'
                        : records.productName}
                    </div>
                    <div>
                      待使用：{cup1}/{cup2}
                    </div>
                  </div>
                </div>
              )
            }
          }
        ]
      },
      {
        type: 'stepper',
        key: 'returnNum',
        labelAlign: 'left',
        maxValue: cup1,
        cols: 5
      }
    ]
  }
}
