export default ({ data, type }) => {
    return {
        title: '发票信息',
        form: [
            {
                type: 'textGroup',
                labelCol: { span: 3 },
                wrapperCol: { span: 24 },
                value: [
                    {
                        name: '发票类型',
                        key: 'invoiceType',
                        value:
                        {
                          1: '蓝票',
                          2: '红票',
                        }[data.invoiceType] || '',
                    },
                    {
                        name: '发票抬头',
                        key: 'buyerName',
                    },
                    {
                        name: '纳税人识别号',
                        key: 'buyerTaxNum',
                    },
                    {
                        name: '发票内容',
                        key: 'ofdUrl',
                        value:"明细"
                    },
                    {
                        name: '开票金额',
                        key: 'invoiceAmount',
                        value:`${data.invoiceAmount}元`
                    },
                    {
                        name: '申请时间',
                        key: 'createTime',
                    },
                    {
                        name: '开户银行',
                        key: 'buyerBankName',
                    },
                    {
                        name: '银行账户',
                        key: 'buyerBankAccount',
                    },
                    {
                        name: '企业地址',
                        key: 'buyerAddress',
                    },
                    {
                        name: '企业电话',
                        key: 'buyerTel',
                    }
                ],
            },
        ],
    }
}
