function getTableColumns(data) {
  const columns = [].concat(data.comboDetails)
  const arr = [
    {
      name: `V${data.memberLevel}优惠`,
      value: '-￥' + data.memberDiscountAmount,
      display: data.memberDiscountAmount,
    },
    // {
    //   name: '运费',
    //   value: '￥' + data.freightAmount,
    //   display: data.freightAmount
    // },
    // {
    //   name: '运费',
    //   value: '包邮',
    //   display: !data.freightAmount
    // },
    {
      name: '优惠券',
      //   name: `优惠券${data.couponName}`,
      value: '-￥' + data.couponAmount,
      display: data.couponAmount,
    },
    {
      name: '余额券',
      value: '-￥' + data.balanceAmount,
      display: data.balanceAmount,
    },
    {
      name: '实付金额',
      value: '￥' + data.payAmount,
      display: data.payAmount,
    },
  ].filter((e) => e.display)
  arr.forEach((e) => {
    columns.push(e)
  })
  return columns
}
export default function () {
  const { data, type } = arguments[0]
  return {
    class: 'order-table',
    form: [
      {
        type: 'table',
        wrapperCol: 24,
        dataSource: getTableColumns(data),
        showPagination: false,
        columns: [
          {
            title: '酒店/景点名称',
            dataIndex: 'businessName',
            type: 'ct-200',
            display: true,
          },
          {
            title: '商品名称',
            dataIndex: 'productName',
            type: 'ct-100',
            display: true,
          },
          {
            title: '单价（元）',
            dataIndex: 'salePrice',
            type: 'ct-100',
            display: true,
          },
          {
            title: '数量',
            dataIndex: 'productQuantity',
            type: 'ct-100',
            display: true,
            customRender: (text, records) => {
              if (records.productQuantity) {
                if (!records.hotelRoomName) {
                  return records.productQuantity + '张'
                } else {
                  return records.productQuantity + '晚'
                }
              }
            },
          },
          {
            title: '原价总（元）',
            dataIndex: 'salePriceTotal',
            type: 'ct-100',
            display: true,
            // fixed: 'right',
            customRender: (text, records, index, h) => {
              if (index == 0) {
                return {
                  children: data.comboDetails.map((e) => e.salePrice * e.productQuantity).reduce((a, b) => a + b, 0),
                  attrs: {
                    rowSpan: data.comboDetails.length,
                  },
                }
              } else {
                return {
                  attrs: {
                    colSpan: 0,
                  },
                }
              }
            },
          },
          {
            title: '套餐价(元)',
            display: true,
            // fixed: 'right',
            customRender: (text, records, index, h) => {
              if (records.name) {
                return {
                  children: records.name + records.value,
                  attrs: {
                    colSpan: 2,
                    rowSpan: 1,
                  },
                }
              }
              if (index == 0) {
                return {
                  children: data.totalAmount,
                  attrs: {
                    rowSpan: data.comboDetails.length,
                  },
                }
              }
              return {
                attrs: {
                  rowSpan: 0,
                },
              }
            },
          },
        ].filter((e) => e.display),
      },
    ],
  }
}
