export default ({ data }) => {
  return {
    title: '配送',
    form: [
      {
        type: 'textGroup',
        value: [
          {
            name: '收货人电话',
            key: 'receiverPhone'
          },
          {
            name: '配送方式',
            value: data.deliveryType == '0' ? '快递配送' : '自行配送',
            display: data.deliveryType
          },
          {
            name: '收件人',
            key: 'receiverName',
            display: data.receiverName
          },
          {
            name: '快递单号',
            key: 'deliverySn',
            display: data.deliverySn
          },
          {
            name: '收货人电话',
            key: 'receiverPhone',
            display: data.receiverPhone
          },
          {
            name: '收货地址',
            width: '100%',
            display: data.receiverProvince,
            value:
              (data.receiverProvince || '') +
              ' ' +
              (data.receiverCity || '') +
              ' ' +
              (data.receiverRegion || '') +
              ' ' +
              (data.receiverDetailAddress || '')
          },
          {
            name: '订单备注',
            key: 'remark',
            display: data.remark
          }
        ].filter(e => e.display)
      }
    ]
  }
}
