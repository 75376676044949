export default ({ data }) => {
  return {
    title: '记录',
    form: [
      {
        type: 'textGroup',
        labelCol: { span: 3 },
        wrapperCol: { span: 24 },
        value: [
          {
            name: '下单时间',
            key: 'createTime',
            display: data.createTime
          },
          {
            name: '支付时间',
            key: 'paymentTime',
            display: data.paymentTime
          },
          {
            name: '完成时间',
            key: 'endTime',
            display: data.endTime
          },
          {
            name: '关闭时间',
            key: 'updateTime',
            display: data.status == '6'
          }
        ].filter(e => e.display)
      }
    ]
  }
}
