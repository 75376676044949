export default ({ data } = {}) => {
    return {
        title: '记录',
        form: [
            {
                type: 'textGroup',
                value: [
                    {
                        name: '下单时间',
                        key: 'createTime',
                        display: data.createTime,
                    },
                    {
                        name: '关闭时间',
                        key: 'cancelTime',
                        display: data.cancelTime,
                    },
                ].filter((e) => e.display),
            },
        ],
    }
}
