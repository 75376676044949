export default ({ data }) => {
  return {
    title: '基础信息',
    form: [
      {
        type: 'textGroup',
        labelCol: { span: 3 },
        wrapperCol: { span: 24 },
        value: [
          {
            name: '店铺名称',
            key: 'shopName',
            display: data.shopName
          },
          {
            name: '核销截止时间',
            value: data['orderItemList'][0]['effectiveEndTime'],
            display: data['orderItemList'][0]['effectiveEndTime']
          },
          {
            name: '登录手机',
            key: 'phone',
            display: data.phone
          },
          {
            name: '订单编号',
            key: 'orderSn',
            display: data.orderSn
          },
          {
            name: '联系电话',
            value: data.orderItemList[0].concatPhone,
            display: data.orderItemList[0].concatPhone
          },
          {
            name: '备注',
            key: 'remark',
            display: data.remark
          }
        ].filter(e => e.display)
      }
    ]
  }
}
