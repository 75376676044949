function getTableColumns(data) {
  const columns = [].concat(data)
  // const arr = [
  //   {
  //     name: `V${data.memberLevel}优惠`,
  //     value: '-￥' + data.memberDiscountAmount,
  //     display: data.memberDiscountAmount,
  //   },
  //   // {
  //   //   name: '运费',
  //   //   value: '￥' + data.freightAmount,
  //   //   display: data.freightAmount
  //   // },
  //   // {
  //   //   name: '运费',
  //   //   value: '包邮',
  //   //   display: !data.freightAmount
  //   // },
  //   {
  //     name: '优惠券',
  //     //   name: `优惠券${data.couponName}`,
  //     value: '-￥' + data.couponAmount,
  //     display: data.couponAmount,
  //   },
  //   {
  //     name: '余额券',
  //     value: '-￥' + data.balanceAmount,
  //     display: data.balanceAmount,
  //   },
  //   {
  //     name: '实付金额',
  //     value: '￥' + data.payAmount,
  //     display: data.payAmount,
  //   },
  // ].filter((e) => e.display)
  // arr.forEach((e) => {
  //   columns.push({
  //     productQuantity: e.name,
  //     totalPrice: e.value,
  //   })
  // })
  return columns
}
export default function () {
  const { data, type } = arguments[0]
  return {
    class: 'order-table',
    form: [
      {
        type: 'table',
        wrapperCol: 24,
        dataSource: getTableColumns(data),
        showPagination: false,
        columns: [
          {
            title: '活动',
            type: 'lt-200',
            display: true,
            customRender: (text, records, index, h) => {
              if (!records.activityImgUrl || !records.activityName) return null
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  {records.activityImgUrl.trim() && (
                    <img
                      src={records.activityImgUrl.split(',')[0]}
                      style={{
                        width: '30px',
                        height: '30px',
                        marginRight: '10px',
                      }}
                    />
                  )}
                  <div
                    style={{
                      maxWidth: '378px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {/* {records.productName.length > 10
                      ? records.productName.substring(0, 10) + '……'
                      : records.productName} */}
                    {records.activityName}
                    <br />
                    {type == 'specialty' ? records.productAttr : ''}
                  </div>
                </div>
              )
            },
          },
          {
            title: '报名费',
            display: true,
            customRender: (text, records, index, h) => {
              return <div>￥ {records.activityPrice || 0}</div>
            },
          },
        ].filter((e) => e.display),
      },
    ],
  }
}
