import { Button } from 'ant-design-vue'

export default ({ typeData, form, type, data, success, setHidden }) => {
  return (data.userCodeVOList || []).map((e, i) => {
    return {
      title: '券' + (i + 1),
      form: [
        {
          type: 'textGroup',
          wrapperCol: 24,
          value: [
            {
              name: '券状态',
              value: ['未使用', '已使用', '已退款'][e.status],
              display: true,
              width: '100%',
              showRight: e.status == '0' && type == 'verification',
              right(h) {
                return (
                  <Button
                    ghost={true}
                    type="danger"
                    style={{ marginTop: '-100px', paddingRight: '10px' }}
                    onClick={() => success({ data: e, setHidden })}
                  >
                    核销
                  </Button>
                )
              }
            },
            {
              name: '时间',
              value: e.usedTime,
              display: e.usedTime
            },
            {
              name: '退款原因',
              value: e.reason,
              width: '100%',
              display: e.reason
            },
            {
              name: '更多原因',
              value: e.description,
              width: '100%',
              display: e.description
            }
          ].filter(e => e.display)
        }
      ]
    }
  })
}
