export default ({ form, data, childrenId = null } = {}) => {
  let productQuantityData = null
  let unuse = data.userCodeVOList.filter(e => e.status == 0).length
  if (childrenId) {
    let arr = form.orderItemList.filter(e => e.id == childrenId)[0]
    productQuantityData = arr.productQuantity
    form.returnCount = form.returnCount ? form.returnCount : 1
    // form.returnAmount =
    //   (form.returnCount = form.returnCount ? form.returnCount : 1) *
    //   arr.productRealPrice
  } else {
    productQuantityData = form.orderItemList[0].productQuantity
    form.returnCount = form.returnCount ? form.returnCount : 1
    // form.returnAmount =
    //   (form.returnCount = form.returnCount ? form.returnCount : 1) *
    //   form.orderItemList[0].productRealPrice
  }

  form.unuse = unuse

  return {
    title: '售后处理信息',
    form: [
      {
        name: '处理结果',
        key: 'handleResult',
        type: 'radioButton',
        display: true,
        defaultValue: '0',
        labelAlign: 'left',
        typeData: [
          {
            name: '同意退款',
            value: '0',
            display: true
          },
          {
            name: '退款退货',
            value: '1',
            display: ['facility', 'eatDrink'].indexOf(data.type) !== -1 ? false : true
          },
          {
            name: '供应商补发',
            value: '2',
            display: ['facility', 'eatDrink'].indexOf(data.type) !== -1 ? false : true
          },
          {
            name: '已经处理',
            value: '3',
            display: ['facility', 'eatDrink'].indexOf(data.type) !== -1 ? false : true
          },
          {
            name: '驳回退款',
            value: '4',
            display: ['facility', 'eatDrink'].indexOf(data.type) !== -1 ? false : true
          }
        ].filter(e => e.display)
      },
      {
        type: 'inputNumber',
        name: '商品数量',
        key: 'returnCount',
        labelAlign: 'left',
        wrapperCol: { span: 21 },
        display:
          ['facility', 'eatDrink'].indexOf(data.type) == -1
            ? form.handleResult == '0' || form.handleResult == '1'
            : false,
        props: {
          min: 1,
          max: productQuantityData
        }
      },
      {
        type: 'inputNumber',
        name: '退款金额',
        key: 'returnAmount',
        labelAlign: 'left',
        wrapperCol: { span: 21 },
        display:
          ['facility', 'eatDrink'].indexOf(data.type) == -1
            ? form.handleResult == '0' || form.handleResult == '1'
            : false,
        props: {
          addonBefore: '￥',
          min: 0
          //   max: form.returnAmount
        }
      },
      {
        name: '处理的券',
        type: 'stepper',
        key: 'returnNum',
        labelAlign: 'left',
        display: data.type == 'eatDrink',
        desc: '不能减少：0时<br/>不能增加：达到剩余未使用最大值',
        maxValue: form.unuse
      },
      {
        name: '问题描述',
        type: 'textArea',
        key: 'handleNote',
        labelAlign: 'left',
        display: true,
        wrapperCol: { span: 21 }
      }
    ].filter(e => e.display)
  }
}
