export default ({ data, type }) => {
    return {
        title: '接受信息',
        form: [
            {
                type: 'textGroup',
                labelCol: { span: 3 },
                wrapperCol: { span: 24 },
                value: [
                    {
                        name: '电子邮箱',
                        key: 'email',
                    },
                    {
                        name: '联系电话',
                        key: 'buyerPhone',
                    },
                ],
            },
        ],
    }
}
