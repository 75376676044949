function getTableColumns(data) {
  const columns = [].concat(data.visitorList)
  return columns
}

export default function() {
  const { data, type } = arguments[0]
  return {
    class: 'order-table',
    form: [
      {
        display: true,
        type: 'table',
        wrapperCol: 24,
        dataSource: getTableColumns(data),
        showPagination: false,
        columns: [
          {
            title: '姓名',
            dataIndex: 'concatName',
            display: true
          },
          {
            title: '手机号',
            dataIndex: 'concatPhone',
            display: true
          },
          {
            title: '时间',
            display: true,
            dataIndex: 'createTime'
          }
        ].filter(e => e.display)
      }
    ].filter(e => e.display)
  }
}
