export default ({ data }) => {
  return {
    title: '第三方票务',
    form: [
      {
        type: 'textGroup',
        labelCol: { span: 3 },
        wrapperCol: { span: 24 },
        value: [
          {
            name: '票务平台',
            key: 'thirdPlatform',
            value: { 0: '自我游', 1: '云上游' }[data.thirdPlatform],
            display: data.thirdPlatform
          },
          {
            name: '推送状态',
            key: 'pushStatus',
            value: { 0: '未推送', 1: '推送成功', 2: '推送失败' }[data.pushStatus],
            display: data.thirdOrderNo && data.pushStatus
          },
          {
            name: '第三方订单号',
            key: 'thirdOrderNo',
            display: data.thirdOrderNo
          }
          //   {
          //     name: '门票id',
          //     key: 'orderSn',
          //     display: data.orderSn
          //   }
        ].filter(e => e.display)
      }
    ]
  }
}
