function getTableColumns(data) {
  const columns = [].concat(data.visitorList)
  return columns
}
export default function () {
  const { data, type } = arguments[0]
  return {
    class: 'order-table',
    form: [
      {
        type: 'table',
        wrapperCol: 24,
        dataSource: getTableColumns(data),
        showPagination: false,
        columns: [
          {
            title: '姓名',
            align: 'center',
            dataIndex: 'concatName',
          },
          {
            title: '手机号',
            align: 'center',
            dataIndex: 'concatPhone',
          },
          {
            title: '身份证',
            align: 'center',
            dataIndex: 'concatIdCard',
          },
        ],
      },
    ],
  }
}
