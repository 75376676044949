import moment from 'moment'
import { orderStateList2 } from '@/utils/textFile'
export default ({ data }) => {
  return {
    title: '基础信息',
    form: [
      {
        type: 'textGroup',
        value: [
          {
            name: '订单编号',
            key: 'orderSn',
            display: data.orderSn,
          },
          {
            name: '订单状态',
            display: data.status,
            value: data.refundId
              ? '待退款'
              : (orderStateList2.find((e) => e.value == data.status) || { text: '' }).text,
          },
          {
            name: '店铺名称',
            key: 'shopName',
            display: data.shopName,
          },
          {
            name: '入住时间',
            value: data.orderItemList ? moment(data.orderItemList[0].checkInTime).format('YYYY年MM月DD日') : '',
            display: data.orderItemList ? data.orderItemList[0].checkInTime : false,
          },
          {
            name: '用户姓名',
            key: 'buyName',
            display: data.buyName,
          },
          {
            name: '离店时间',
            value: data.orderItemList ? moment(data.orderItemList[0].leaveTime).format('YYYY年MM月DD日') : '',
            display: data.orderItemList ? data.orderItemList[0].leaveTime : false,
          },
          {
            name: '手机号',
            key: 'buyPhone',
            display: data.buyPhone,
          },
          {
            name: '备注',
            key: 'remark',
            display: data.remark,
          },
        ].filter((e) => e.display),
      },
    ],
  }
}
