
function getTableColumns(data){
    return data.orderItemList
}

export default function () {
    const { data, type } = arguments[0]
    return {
        class: 'order-table',
        form: [
            {
                type: 'table',
                wrapperCol: 24,
                dataSource: getTableColumns(data),
                showPagination: false,
                columns: [
                    {
                        title: '姓名',
                        dataIndex: 'passengerName',
                        display: true,
                    },
                    {
                        title: '票种类型',
                        dataIndex: 'ticketTypeName',
                        display: true,
                    },
                    {
                        title: '手机号',
                        dataIndex: 'passengerPhone',
                        display: true,
                    },
                    {
                        title: '身份证',
                        display: true,
                        dataIndex: 'passengerCertNo',

                    },

                ].filter((e) => e.display),
            },
        ],
    }
}
