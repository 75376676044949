import { delAction, getAction } from '@/command/netTool'
import { message, Modal } from 'ant-design-vue'

export default ({ data }) => {
  let { deliveryList, valCopy } = data
  if (deliveryList.length == 0)
    return {
      title: '物流信息'
    }
  return {
    form: [
      {
        type: 'tabs',
        typeData: deliveryList,
        onClick: e => {
          data.valCopy = e
        }
      },
      {
        label: '删除当前信息',
        type: 'popButton',
        width: '100%',
        props: {
          ghost: true,
          type: 'danger',
          style: {
            width: '100px'
          }
        },
        popConfirm: {
          popTitle: '确定删除当前数据吗？'
        },
        onClick: () => {
          delAction('/product/order/shippingDel?id=' + valCopy.id).then(res => {
            if (res.code == 200) {
              message.success('删除成功')
              getAction(`/api/product/order/detail?id=${data.id}`).then(result => {
                data.deliveryList = result.data.deliveryList
                data.valCopy = result.data.deliveryList.length > 0 ? result.data.deliveryList[0] : {}
              })
            } else {
              message.error(res.msg)
            }
          })
        }
      },
      {
        type: 'textGroup',
        value: [
          {
            name: '配送方式',
            width: '100%',
            display: valCopy.deliveryType,
            value: valCopy.deliveryType == '0' ? '快递配送' : '自行配送'
          },
          {
            name: '快递公司',
            width: '100%',
            display: valCopy.deliveryCompany,
            value: valCopy.deliveryCompany
          },
          {
            name: '快递单号',
            width: '50%',
            display: valCopy.deliverySn,
            value: valCopy.deliverySn
          }
        ].filter(e => e.display)
      },
      {
        type: 'timeline',
        typeData: valCopy.logisticsList.map(e => {
          return {
            name: e.acceptStation,
            value: e.acceptTime
          }
        })
      }
    ]
  }
}
